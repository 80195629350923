export function enableEventTrackingOnNavLinks(links, groupType) {
  if (links.length === 0) {
    return;
  }

  links.forEach((link) => {
    link.removeAttribute('data-v-group');
    link.removeAttribute('data-v-item');
  });

  let visibleItems = filterVisibleItems(links, groupType);

  if (visibleItems) {
    visibleItems.forEach((link, index) => {
      index++;
      if (shouldTrackItem(link)) {
        const linkText =
          link.getAttribute('id') === 'idcta-link'
            ? 'BBC Account'
            : link.innerText;
        const destinationUrl = link.getAttribute('href');

        link.setAttribute(
          'data-v-item',
          JSON.stringify({
            type: 'link',
            text: linkText,
            link: destinationUrl,
            position: index,
          }),
        );
        link.setAttribute(
          'data-v-group',
          JSON.stringify({ type: groupType, item_count: visibleItems.length }),
        );
        link.setAttribute('data-bbc-ignore-views', 'true');
      }
    });
  }
}

const navigationContainer = document.getElementById('orb-banner');

// Options for the observer (which mutations to observe)
const config = { attributes: true, subtree: true };

// Callback function to execute when mutations are observed
const callback = (mutationList) => {
  Array.from(mutationList).forEach((mutation) => {
    const element = mutation.target;
    if (
      mutation.attributeName === 'role' &&
      element.getAttribute('role') === 'button'
    ) {
      element.removeAttribute('data-v-item');
      element.removeAttribute('data-v-group');
    }
  });
};

// Create an observer instance linked to the callback function
const observer = new MutationObserver(callback);
if (navigationContainer) {
  observer.observe(navigationContainer, config);
}

function shouldTrackItem(link) {
  return link.getAttribute('role') !== 'button';
}

function filterVisibleItems(links, groupType) {
  if (groupType === 'global navigation') {
    return links.filter(
      (link) =>
        link.offsetParent !== null &&
        window.getComputedStyle(link).visibility !== 'hidden' &&
        !link.closest('.orb-skip-links'),
    );
  } else if (groupType === 'more menu') {
    /*
    Filter the more menu items differently due to it not being visible on page load:
      - close button doesn't count as a clickable item
      - only use items in the correct nav displayed after geolocation lookup
      - only use items that are shown in the more menu
    */
    return links.filter(
      (link) =>
        link.getAttribute('role') !== 'button' &&
        window.getComputedStyle(link.closest('nav')).display !== 'none' &&
        window.getComputedStyle(link.closest('li')).display !== 'none',
    );
  }
}

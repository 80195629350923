import { enableEventTrackingOnNavLinks } from './nav-links-event-tracking';
import { getNavCount } from './common/nav-count.js';

const watch = require('./orbit/font-watcher.js').default;

const navigationContainer = document.getElementById('orb-banner');
const moreMenuContainer = document.getElementById('orbit-more-drawer');
let resizeDebounceTimeout;
const resizeDebounceDelay = 250;

function addTrackingToHeaderSections() {
  if (navigationContainer) {
    let navigationHeaderLinks = navigationContainer.querySelectorAll('a');
    enableEventTrackingOnNavLinks(
      [...navigationHeaderLinks],
      'global navigation',
    );
  }

  if (moreMenuContainer) {
    let moreMenuLinks = moreMenuContainer.querySelectorAll('a');
    enableEventTrackingOnNavLinks([...moreMenuLinks], 'more menu');
  }
}

window.bbcuser.isUKCombined().then((isUKCombined) => {
  // Do this first as it's worth doing whether the guard clause below kicks in or not
  if (!isUKCombined) {
    const logoLink = document.getElementById('homepage-link');
    if (logoLink) {
      const ukUrl = logoLink.getAttribute('href');
      const intUrl = ukUrl.replace('.bbc.co.uk', '.bbc.com');
      logoLink.setAttribute('href', intUrl);
    }
  }

  // If the user has already opened the non-JS more drawer, do not bother
  // geo-tagging the header nor footer as it will just confuse things and
  // the `display: none` changes this might cause might even crash
  // screenreaders as elements disappear while focused
  // This might seem overkill for the footer, but halting that too means
  // both the (non-JS) more menu and the footer will show the domestic links
  // consistently.
  if (window.location.hash !== '#orbit-more-drawer') {
    const className = isUKCombined ? 'orbit-domestic-yes' : 'orbit-domestic-no';
    ['orb-banner', 'orbit-more-drawer', 'orb-footer'].forEach((elementId) => {
      const el = document.getElementById(elementId);
      if (el) {
        el.classList.add(className);
      }
    });
  }

  window.additionalPageProperties['suppressView'] =
    window.orbitData.suppressView;
  document.dispatchEvent(
    new CustomEvent('bbc-page-reset', {
      detail: {
        page: {
          additionalProperties: window.additionalPageProperties,
        },
      },
    }),
  );

  addTrackingToHeaderSections();
});

window.addEventListener('resize', function () {
  clearTimeout(resizeDebounceTimeout);
  resizeDebounceTimeout = setTimeout(() => {
    addTrackingToHeaderSections();
    window.bbcpage
      .updateAdditionalProperties({
        experience_global_nav_count: getNavCount().toString(),
      })
      .catch((e) => console.error(e));
  }, resizeDebounceDelay);
});

Promise.all([
  window.bbcpage.isModal(),
  watch('ReithSans', 'b-reith-sans-loaded'),
]).then((results) => {
  // TODO remove when legacy orb goes away
  if (
    // if in legacy orbit
    window.require.defined &&
    window.require.defined('orb/features/masthead/_layoutprimarynav')
  ) {
    const hasHeader = !results[0];
    if (hasHeader) {
      window.require(['orb/features/masthead/_layoutprimarynav'], (layout) =>
        layout.recalculateLayout(),
      );
    }
  }
});

if (document.documentElement.className.includes('b-reith-serif-font')) {
  watch('ReithSans', 'b-reith-serif-loaded');
}

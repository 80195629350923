function getNavCount() {
  const navigationContainer = document.getElementById('orb-banner');
  let visibleLinks = 0;

  if (navigationContainer) {
    let navigationHeaderLinks = navigationContainer.querySelectorAll('a');

    for (let i = 0; i < navigationHeaderLinks.length; i++) {
      let link = navigationHeaderLinks[i];
      let isLinkVisible =
        link.offsetParent !== null &&
        window.getComputedStyle(link).visibility !== 'hidden' &&
        !link.closest('.orb-skip-links');

      if (isLinkVisible) {
        visibleLinks++;
      }
    }
  }
  return visibleLinks;
}

module.exports = { getNavCount };
